import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardTitle,
  Fade,
  Spinner
} from "reactstrap";
import { createUser } from "../services/userService";
import { getAdminMailPrefs } from "../services/mail";
import { MailMessage } from "../models/Message";
import { appInfo } from "../appInfo";
import "./Guest.css";

const stage = appInfo.stage;

const getRegRecipients = (adminMailPrefs) => {
  const admins = adminMailPrefs.users.map((u) => {
    if (u.notifications.registration) return { name: u.name, address: u.email };

    return null;
  });

  return admins.filter((u) => u);
};

const Guest = (props) => {
  const { auth } = props;

  const { instance } = useMsal();

  const { user, sendEmail } = props;
  const registration = user.registration ?? null;
  const [requested, setRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [error, setError] = useState(false);
  const [areThereAdmins, setAreThereAdmins] = useState(true);

  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFadeIn(true);
    }, 1000);

    return () => clearTimeout(timeout);
  });

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/"
    });
  };

  const sendRequest = async (adminMailPrefs) => {
    const regRecipients = getRegRecipients(adminMailPrefs);

    // Set mail delegate
    const fromRecipient = {
      name: user.name,
      address: user.email
    };

    // Set to recipients
    const toRecipients = regRecipients;

    // Set message subject
    const subject = `${appInfo[stage].longName}: Access request`;

    const adminGreet = () => {
      return regRecipients.length === 1
        ? regRecipients[0].name
        : "Administrators";
    };

    // Set message body
    const body = {
      content: `Dear ${adminGreet()},\n\nI'm requesting access to the ${
        appInfo[stage].longName
      } System.\n\nThank you,\n${user.name}`
    };

    // Set one attachment
    const attachments = [];

    // Set Cc list
    const ccRecipients = [];

    // Set Bcc list
    const BccRecipients = [];

    const message = MailMessage(
      fromRecipient,
      toRecipients,
      subject,
      body,
      attachments,
      ccRecipients,
      BccRecipients,
      true
    );

    // Send email request
    sendEmail(message, setResults);
  };

  const handleRequest = async () => {
    const adminMailPrefs = await getAdminMailPrefs(auth.idToken);

    if (adminMailPrefs === null) {
      setAreThereAdmins(false);

      return false;
    }

    const guest = {
      id: user.id,
      name: user.name,
      org: "",
      email: user.email,
      role: user.role,
      prefs: {
        notifications: {
          registration: false
        }
      },
      registration: "pending"
    };

    setIsLoading(true);

    await createUser(guest, auth.idToken)
      .then(async () => {
        await sendRequest(adminMailPrefs);

        setIsLoading(false);
        setRequested(true);
      })
      .catch((e) => {
        setError(new Error("Asynchronous error"));
      });
  };

  const RequestRegistration = () => {
    return (
      <Card>
        <CardHeader tag="h2">User access</CardHeader>
        <CardBody>
          <CardTitle>
            {user.name}, you are currently not authorized to use this
            application.
          </CardTitle>
          <CardText>
            If you wish to request access, please click on the button below.
          </CardText>
          {error && (
            <Alert color="danger">
              Having trouble contacting the server. Please contact the
              administrator or try again later.
            </Alert>
          )}
          {!results && (
            <Button
              color="primary"
              disabled={isLoading}
              onClick={handleRequest}
            >
              Request access{" "}
              {isLoading && <Spinner size="sm" color="primary" />}
            </Button>
          )}
        </CardBody>
      </Card>
    );
  };

  const PendingRegistration = () => {
    return (
      <Card>
        <CardHeader tag="h2">User access</CardHeader>
        <CardBody>
          <Alert color="danger">
            <h4 className="alert-heading">Important!</h4>
            <p className="mb-0">
              {user.name}, your request for access is currently being reviewed
              by the administrators. Please log out now and come back after you
              receive the welcome email from the administrator who approved your
              request.
            </p>
            <Button
              className="mt-1rem"
              size="sm"
              color="danger"
              onClick={handleLogout}
            >
              Log Out
            </Button>
          </Alert>
        </CardBody>
      </Card>
    );
  };

  const RejectedRegistration = () => {
    return (
      <Card>
        <CardHeader tag="h2">User access</CardHeader>
        <CardBody>
          <CardTitle>
            {user.name}, your request for access was rejected by the
            administrator.
          </CardTitle>
        </CardBody>
      </Card>
    );
  };

  const SuspendedRegistration = () => {
    return (
      <Card>
        <CardHeader tag="h2">User access</CardHeader>
        <CardBody>
          <CardTitle>
            {user.name}, access to this application is currently suspended until
            further notice.
          </CardTitle>
        </CardBody>
      </Card>
    );
  };

  return (
    <Fade in={fadeIn}>
      <div className="Guest">
        {!areThereAdmins && <SuspendedRegistration />}
        {!registration && areThereAdmins && !requested && (
          <RequestRegistration />
        )}
        {(registration === "pending" || requested) && <PendingRegistration />}
        {registration === "rejected" && <RejectedRegistration />}
      </div>
    </Fade>
  );
};

export default Guest;
