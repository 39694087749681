import axios from "axios";

const baseUrl = process.env.REACT_APP_APPREG_APIBASEURL;
const hostKey = process.env.REACT_APP_AZFUNCTIONS_HOSTKEY;

const getCrewMember = async (id, token = null) => {
  const url = `${baseUrl}/api/crewMemberGet?code=${hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: { id }
  });

  return data;
};

const getCrewMembers = async (query, token = null) => {
  const url = `${baseUrl}/api/crewMembersList?code=${hostKey}`;

  const { data } = await axios({
    method: "GET",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: query
  });

  return data;
};

const createCrewMember = async (doc, token = null) => {
  const url = `${baseUrl}/api/crewMemberInsert?code=${hostKey}`;

  const { data } = await axios({
    method: "POST",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: doc
  });

  return data;
};

const updateCrewMember = async (id, doc, token = null) => {
  const url = `${baseUrl}/api/crewMemberUpdate?code=${hostKey}`;
  const { data } = await axios({
    method: "PUT",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: { id },
    data: doc
  });

  return data;
};

const deleteCrewMember = async (id, token = null) => {
  const url = `${baseUrl}/api/crewMemberDelete?code=${hostKey}`;

  const { data } = await axios({
    method: "DELETE",
    url: url,
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: { id }
  });

  return data;
};

export {
  getCrewMember,
  getCrewMembers,
  createCrewMember,
  updateCrewMember,
  deleteCrewMember
};
