import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import NotFound from "../containers/NotFound";
import Revoked from "../containers/Revoked";
import { getContainerAccessRoles } from "../models/RoleAccess";

const AuthenticatedRoute = ({ component: C, appProps, ...rest }) => {
  const isAuthenticated = useIsAuthenticated();
  const { user } = appProps;
  const { path } = { ...rest };

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          user.registration === "revoked" ? (
            <Revoked {...props} {...appProps} />
          ) : getContainerAccessRoles(path).indexOf(user.role) !== -1 ? (
            <C {...props} {...appProps} />
          ) : (
            <NotFound {...props} {...appProps} />
          )
        ) : (
          <Redirect
            to={`/login?redirect=${props.location.pathname}${props.location.search}`}
          />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
