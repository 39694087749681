import React from "react";
import AuthTest from "../components/AuthTest";
import "./Auth.css";

const Auth = (props) => {
  return (
    <div className="Auth">
      <div className="container">
        <AuthTest {...props} />
      </div>
    </div>
  );
};

export default Auth;
