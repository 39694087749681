import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner
} from "reactstrap";
import AdminGrantModal from "./AdminGrantModal";
import classnames from "classnames";
import { updateUser, deleteUser } from "../services/userService";
import { getUserRoles } from "../models/UserRoles";
import { MailMessage } from "../models/Message";
import { phraseToProperCase } from "../libs/case-utils";
import Moment from "react-moment";
import "moment-timezone";
import { appInfo } from "../appInfo";
import "./User.css";

const stage = appInfo.stage;

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM/DD/YYYY HH:mm";

const roleOptions = getUserRoles();

const User = (props) => {
  const { selectedUser, systemUser, sendEmail, auth } = props;

  const [user, setUser] = useState(selectedUser);
  const [isDeleted, setIsDeleted] = useState(false);

  // Control tabs
  const [activeTab, setActiveTab] = useState("1");
  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getHeaderClass = () => {
    let status = null;

    switch (user.registration) {
      case "approved":
        status = "bg-success text-white";
        break;
      case "rejected":
        status = "bg-danger text-white";
        break;
      case "revoked":
        status = "bg-danger text-white";
        break;
      case "pending":
        status = "bg-warning text-white";
        break;
      default:
        status = "bg-warning text-white";
    }

    return status;
  };

  const sendApprovalNotification = async (status) => {
    // Set mail delegate
    const fromRecipient = {
      name: systemUser.name,
      address: systemUser.email
    };

    // Set to recipients
    const toRecipients = [
      {
        name: user.name,
        address: user.email
      }
    ];

    // Set message subject
    const subject = `${appInfo[stage].longName}: Access request`;

    // Set message according to status
    let statusMessage = `Dear ${user.name},\n\nYour request to use the ${appInfo[stage].longName} was approved.\n\nThank you,\n${systemUser.name}`;
    if (status === "rejected")
      statusMessage = `Dear ${user.name},\n\nYour request to use the ${appInfo[stage].longName} was rejected.\n\nThank you,\n${systemUser.name}`;

    // Set message body
    const body = {
      content: statusMessage
    };

    // Set one attachment
    const attachments = [];

    // Set Cc list
    const ccRecipients = [];

    // Set Bcc list
    const BccRecipients = [];

    const message = MailMessage(
      fromRecipient,
      toRecipients,
      subject,
      body,
      attachments,
      ccRecipients,
      BccRecipients,
      true
    );

    sendEmail(message);
  };

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const EditButton = (props) => {
    const { handleEdit } = props;

    return (
      <Button
        outline
        color="primary"
        size="sm"
        onClick={() => handleEdit(true)}
      >
        Edit
      </Button>
    );
  };

  const DeleteButton = (props) => {
    const { handleDelete } = props;

    return (
      <Button
        outline
        color="danger"
        size="sm"
        className="ml-05rem"
        onClick={() => handleDelete(true)}
      >
        Delete
      </Button>
    );
  };

  const SaveCancelButtons = (props) => {
    const { handleSave, handleCancel, isLoading, isError } = props;

    return (
      <>
        <Button
          color="primary"
          size="sm"
          onClick={handleSave}
          disabled={isError}
        >
          Save {isLoading && <Spinner size="sm" color="light" />}
        </Button>
        <Button color="default" size="sm" onClick={handleCancel}>
          Cancel
        </Button>
      </>
    );
  };

  const DeleteCancelButtons = (props) => {
    const { handleDelete, handleCancelDelete, isLoading } = props;

    return (
      <>
        <CardText>Are you sure you want to delete this user?</CardText>
        <Button color="success" size="sm" onClick={handleCancelDelete}>
          Not sure
        </Button>
        <Button
          className="ml-05rem"
          color="danger"
          size="sm"
          onClick={handleDelete}
        >
          Yes, I'm sure {isLoading && <Spinner size="sm" color="light" />}
        </Button>
      </>
    );
  };

  const GeneralTab = (props) => {
    const {
      userId,
      systemUser,
      role: defRole,
      org: defOrganization,
      registration: defRegistration,
      handleUserUpdate
    } = props;

    const itsMe = userId === systemUser.id;
    const [showAdminGrant, setShowAdminGrant] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [role, setRole] = useState(defRole);
    const [registration, setRegistration] = useState(defRegistration);
    const [organization, setOrganization] = useState(defOrganization);
    const [orgError, setOrgError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const isAdmin = role === "admin" || role === "it-admin";

    const allowEdit = () => {
      const sysUserRole = systemUser.role;
      const managedUserRole = role;
      const itAdmins = ["it-admin", "dev"];

      let allow = false;

      if (itAdmins.includes(sysUserRole)) {
        allow = true;
      } else {
        if (sysUserRole === "admin" && !itAdmins.includes(managedUserRole))
          allow = true;
      }

      return allow;
    };

    const overrideRoleOptions = () => {
      let newRoleOptions = [...roleOptions];
      const sysUserRole = systemUser.role;
      const itAdmins = ["it-admin", "dev"];

      if (!itAdmins.includes(sysUserRole)) {
        newRoleOptions = newRoleOptions.filter(
          (item) => !itAdmins.includes(item.role)
        );
      }

      return newRoleOptions;
    };

    const validateInput = () => {
      const isEmpty = organization === "";
      const passedTest = /^[A-Za-z0-9 ]*$/.test(organization);

      if (isEmpty || !passedTest) {
        setOrgError("Field cannot be empty or the last character is invalid");

        return false;
      } else {
        setOrgError(null);

        return true;
      }
    };

    const handleSave = async () => {
      if (!validateInput()) return false;

      const isNewAdmin = isAdmin && registration === "approved";
      const newRegistration = defRole === "guest" && defRole !== role;
      const rejectedGuest = role === "guest" && registration === "rejected";
      const notify = newRegistration || rejectedGuest;

      setIsLoading(true);

      await updateUser(
        userId,
        {
          org: organization,
          role,
          registration,
          prefs: {
            notifications: {
              registration: isNewAdmin
            }
          }
        },
        auth.idToken
      ).then(() => {
        setIsEditing(false);
        setIsLoading(false);

        // Send notification when registration is approved
        if (notify) sendApprovalNotification(registration);

        handleUserUpdate({
          ...user,
          org: organization,
          role,
          registration
        });
      });
    };

    const handleCancel = () => {
      setOrganization(defOrganization);
      setRole(defRole);
      setRegistration(defRegistration);

      setIsEditing(false);
      setOrgError(null);
    };

    const handleDelete = async () => {
      setIsLoading(true);

      await deleteUser(userId, auth.idToken).then((results) => {
        setIsDeleting(false);
        setIsLoading(false);

        if (results.deletedCount && results.deletedCount > 0) {
          setIsDeleted(true);
        } else {
          showCustomModal({
            title: "Error Deleting User",
            message:
              "Something wrong happened trying to delete user. Please contact the administrator."
          });
        }
      });
    };

    const handleCancelDelete = () => {
      setIsDeleting(false);
    };

    const handleRole = (role) => {
      if (isAdmin) {
        setRole(role);
        setShowAdminGrant(true);
      } else {
        setRole(role);
      }

      if (role === "guest") {
        setRegistration("pending");
      }
    };

    const RoleOption = () => {
      const isApproved = registration === "approved";
      const isPending = registration === "pending";
      const isRejected = registration === "rejected";
      const roleClass = isApproved
        ? "success"
        : isPending
        ? "warning"
        : isRejected
        ? "danger"
        : "default";

      return isEditing ? (
        <Input
          className="mt-1rem"
          value={role}
          type="select"
          id={`role-${userId}`}
          onChange={(e) => handleRole(e.target.value)}
        >
          {overrideRoleOptions().map((el, i) => {
            return (
              <option key={i} value={el.role}>
                {el.label}
              </option>
            );
          })}
        </Input>
      ) : (
        <Badge color={roleClass} className="text-uppercase text-white">
          {phraseToProperCase(role)}
        </Badge>
      );
    };

    const handleAdminGrant = (role, status) => {
      setRole(role);
      setRegistration(status);
    };

    const RegistrationSelect = () => {
      const guestOptions = ["pending", "rejected"];
      let defOptions = ["approved", "revoked"];

      if (role === "guest") defOptions = guestOptions;

      let overideRegistration = registration;
      if (!defOptions.includes(registration)) {
        overideRegistration = defOptions[0];

        setRegistration(overideRegistration);
      }

      return (
        <Input
          className="text-capitalize access"
          defaultValue={overideRegistration}
          type="select"
          id={`registration-${userId}`}
          onChange={(e) => setRegistration(e.target.value)}
        >
          {defOptions.map((o, i) => (
            <option key={i} value={o}>
              {o}
            </option>
          ))}
        </Input>
      );
    };

    const RegistrationOption = () => {
      const isApproved = registration === "approved";
      const isPending = registration === "pending";
      const isRejected = registration === "rejected";
      const registrationClass = isApproved
        ? "success"
        : isPending
        ? "warning"
        : isRejected
        ? "danger"
        : "default";

      return isEditing ? (
        <RegistrationSelect />
      ) : (
        <Badge color={registrationClass} className="text-uppercase text-white">
          {registration}
        </Badge>
      );
    };

    const handleOrganization = (organization) => {
      const isEmpty = organization === "";
      const passedTest = /^[A-Za-z0-9 ]*$/.test(organization);

      if (isEmpty || !passedTest) {
        setOrgError("Field cannot be empty or the last character is invalid");
        setOrganization(organization);

        return false;
      } else {
        setOrgError(null);
        setOrganization(organization);

        return true;
      }
    };

    return (
      <>
        <TabPane tabId="1">
          <CardBody>
            <CardText>
              <b>Name:</b> {user.name}
            </CardText>
            <CardText>
              <b>Email:</b> {user.email}
            </CardText>
            <CardText>
              <b>Registered since:</b> <Moment>{user.createdAt}</Moment>
            </CardText>
            <CardText>
              <b>Organization:</b>{" "}
              {!isEditing && (organization === "" ? "Not set" : organization)}
            </CardText>
            {isEditing && (
              <>
                <Input
                  className="Org"
                  invalid={orgError ? true : false}
                  type="text"
                  name={`organization-name-${userId}`}
                  id={`organization-id-${userId}`}
                  value={organization}
                  disabled={!isEditing}
                  onChange={(e) => handleOrganization(e.target.value)}
                />
                {orgError && (
                  <FormFeedback className="Org">{orgError}</FormFeedback>
                )}
              </>
            )}
            <CardText>
              <b>Role:</b> <RoleOption />
            </CardText>
            <CardText>
              <b>Access:</b> <RegistrationOption />
            </CardText>
          </CardBody>
          {allowEdit() && (
            <CardFooter>
              {!isEditing && !isDeleting && (
                <EditButton handleEdit={setIsEditing} />
              )}
              {isEditing && (
                <SaveCancelButtons
                  handleSave={handleSave}
                  handleCancel={handleCancel}
                  isLoading={isLoading}
                  isError={orgError !== null}
                />
              )}
              {!isDeleting && !itsMe && !isEditing && (
                <DeleteButton handleDelete={setIsDeleting} />
              )}
              {isDeleting && (
                <DeleteCancelButtons
                  handleDelete={handleDelete}
                  handleCancelDelete={handleCancelDelete}
                  isLoading={isLoading}
                />
              )}
            </CardFooter>
          )}
        </TabPane>
        <AdminGrantModal
          role={role}
          show={showAdminGrant}
          setShow={setShowAdminGrant}
          onYes={handleAdminGrant}
          prevGrant={{
            role: selectedUser.role,
            status: selectedUser.registration
          }}
        />
      </>
    );
  };

  return (
    !isDeleted && (
      <div className="User">
        <Card className="UserCard">
          <CardHeader tag="h5" className={getHeaderClass()}>
            {user.name}
          </CardHeader>
          <Nav tabs>
            <NavItem className="ml-025rem">
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTabs("1");
                }}
              >
                General
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <GeneralTab
              userId={user.id}
              systemUser={systemUser}
              role={user.role}
              org={user.org}
              registration={user.registration}
              prefs={user.prefs}
              handleUserUpdate={setUser}
            />
          </TabContent>
        </Card>
        <ErrorModal />
      </div>
    )
  );
};

export default User;
