import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Form,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
  Spinner,
  CardFooter
} from "reactstrap";
import ImportCrewMemberResourcesComp from "./ImportCrewMemberResourcesComp";
import { deleteCrewMembers } from "../services/importServices";
import {
  getCrewMemberTemplateHeaders,
  getCrewMemberFieldDefinition
} from "../models/importCrewMember";
import _ from "lodash";
import bsCustomFileInput from "bs-custom-file-input";
import prettyBytes from "pretty-bytes";
import csv from "csvtojson/v2";
import "./DeleteCrewMemberDataComp.css";
import { phraseToProperCase } from "../libs/case-utils";

const DEBUG = false;
const MAX_UPLOAD_SIZE = 60000000; // 60Mb
// const CALL_DELAY = 50;
const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

const DeleteCrewMemberDataComp = (props) => {
  const { auth } = props;

  let csvFileReader;

  const [errors, setErrors] = useState(null);
  const [results, setResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isReporting, setIsReporting] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [csvContent, setCsvContent] = useState(null);
  const [filename, setFilename] = useState(null);
  const [log, setLog] = useState([]);

  const [progress, setProgress] = useState(0);
  const [progressMax, setProgressMax] = useState(null);

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  useEffect(() => {
    bsCustomFileInput.init();
  }, []);

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const resetForm = () => {
    // Reset form state
    setErrors(null);
    setResults(null);
    setCsvContent(null);
    setFilename(null);
    setLog([]);

    // Reset form
    document.getElementById("upload-form").reset();
  };

  const handleCsvFileRead = async () => {
    const content = csvFileReader.result;

    setCsvContent(content);
  };

  const checkCsvFile = (name, type) => {
    let isCsv = false;

    // Check content type
    const csvContentTypes = [
      "text/csv", // For Unix
      "application/vnd.ms-excel" // For Windows
    ];

    if (type === "") {
      const nameParts = name.split(".");

      isCsv = ["csv", "CSV"].includes(nameParts[1].toLowerCase());
    } else {
      isCsv = csvContentTypes.includes(type);
    }

    return isCsv;
  };

  const handleCsvFileChange = async (e) => {
    const file = e.target.files[0] ?? null;

    if (file && !checkCsvFile(file.name, file.type)) {
      showCustomModal({
        title: "File type error",
        message: `${file.name} is not a csv file.`
      });

      resetForm();
      return;
    }

    // Check for maximum file size
    if (file && file.size > MAX_UPLOAD_SIZE) {
      showCustomModal({
        title: "File size limit error",
        message: `Please pick a data file smaller than ${prettyBytes(
          MAX_UPLOAD_SIZE
        )}.`
      });

      resetForm();
      return;
    }

    // Check for minimum file size
    if (file && file.size <= 0) {
      showCustomModal({
        title: "File size limit error",
        message: `${file.name} is empty.`
      });

      resetForm();
      return;
    }

    if (file) {
      setFilename(file.name);
      csvFileReader = new FileReader();
      csvFileReader.onloadend = handleCsvFileRead;
      csvFileReader.readAsText(file);
    } else {
      setFilename(null);
    }
  };

  const validateForm = () => {
    return csvContent && csvContent.length > 0;
  };

  const initProgressBar = (total) => {
    setProgress(0);
    setProgressMax(total);
  };

  const resetProgressBar = () => {
    setIsProcessing(false);
    setProgress(0);
    setProgressMax(null);
  };

  const executeDeletes = async (docs) => {
    const totalDocs = docs.length;
    const deletesLog = [];
    let deletes = 0;

    // Initialize progress bar
    initProgressBar(totalDocs);

    // Execute upserts
    for (let i = 0; i < totalDocs; i++) {
      const doc = docs[i];

      const filter = doc.filter;

      const res = await deleteCrewMembers(filter, auth.idToken);

      // Debug
      if (DEBUG) deletesLog.push({ filter, res });

      if (res.status === 200) {
        // if delete: deletedCount=1

        const result = res.response.result;
        const deletedCount = result.deletedCount;

        deletes += deletedCount;
      } else {
        console.log("Error: ", res.status, res.response);
      }

      await delay(50);

      setProgress(i + 1);
    }

    // Debug
    if (DEBUG) {
      setLog(deletesLog);
    }

    setResults({ totalDocs, deletes });

    // Reset progress bar
    resetProgressBar();
  };

  const transformData = (docs) => {
    const newDocs = [];

    for (let i = 0; i < docs.length; i++) {
      const doc = docs[i];
      const type = doc["type"].trim();
      const newDoc = {};
      const cols = Object.keys(doc);

      // Transform cell values
      for (let j = 0; j < cols.length; j++) {
        const col = cols[j];
        const value = doc[col];
        const definition = getCrewMemberFieldDefinition(col);
        let transform = null;

        if (transform) {
          newDoc[col] = value;
        } else {
          if (col === "id") {
            transform = definition.variations[type]?.transform ?? null;
          } else {
            transform = definition?.transform ?? null;
          }

          newDoc[col] = transformValue(value, transform);
        }
      }

      newDocs.push({
        filter: {
          id: newDoc.id,
          type: newDoc.type,
          name: newDoc.name
        }
      });
    }

    return newDocs;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    setShowSpinner(true);

    // Convert csv to json array
    const jsonArray = await csv({
      noheader: false,
      output: "json"
    }).fromString(csvContent);

    setIsLoading(false);
    setShowSpinner(false);
    resetForm();

    if (validateData(jsonArray)) {
      const transformedData = transformData(jsonArray);

      // Start processing data
      setIsReporting(true);
      setIsProcessing(true);

      if (transformedData.length > 0) {
        await executeDeletes(transformedData);
      }
    } else {
      setIsReporting(true);
    }
  };

  const ProgressBar = () => {
    const perc = progressMax ? (progress / progressMax) * 100 : 0;
    const progressLabel = Math.min(progressMax, progress + 1);

    return (
      <>
        <div className="text-center">
          <b>
            Processing row #{progressLabel} of {progressMax}...
          </b>
        </div>
        <Progress color="danger" value={perc} />
      </>
    );
  };

  const ShowProcess = () => {
    return (
      <Card className="mb-1rem">
        <CardHeader tag="h6">Processing Data</CardHeader>
        <CardBody className="overflow-500">
          <ProgressBar />
        </CardBody>
      </Card>
    );
  };

  const ShowResults = () => {
    const { totalDocs, deletes } = results;
    const success = deletes === totalDocs;

    return (
      <div>
        {success && <CardText>Data was successfully deleted!</CardText>}
        <CardText>
          <b>Total rows processed: </b> {totalDocs}
          {deletes > 0 && (
            <>
              <br />
              <b>Total crew members deleted: </b> {deletes}
            </>
          )}
          {deletes < totalDocs && (
            <>
              <br />
              <b>Total crew members not found: </b> {totalDocs - deletes}
            </>
          )}
        </CardText>
      </div>
    );
  };

  const transformValue = (value, transform) => {
    let newValue = value;
    const actions = Object.keys(transform);

    for (let i = 0; i < actions.length; i++) {
      const action = actions[i];
      const isEmpty = value.toString().trim() === "";
      const lcStrVal = _.isString(value)
        ? value.toString().trim().toLowerCase()
        : "";

      switch (action) {
        case "default":
          if (isEmpty) newValue = transform[action];
          break;
        case "lowerCase":
          newValue = value.toLowerCase();
          break;
        case "uppperCase":
          newValue = value.toUpperCase();
          break;
        case "true":
          if (transform[action].includes(lcStrVal)) newValue = true;
          break;
        case "false":
          if (transform[action].includes(lcStrVal)) newValue = false;
          break;
        case "split":
          if (!isEmpty) {
            newValue = value.split(transform[action].delimiter);

            // Trim array items
            newValue = newValue.map((item) => item.trim());

            // Remove empty items
            newValue = newValue.filter((item) => item !== "");
          } else {
            newValue = [];
          }
          break;
        default:
        // Do nothing
      }
    }

    return newValue;
  };

  const checkFormat = (colName, cellValue, row, definition) => {
    const isRequired = definition.required ?? false;
    const type = definition.type ?? "string";
    const overrideType = definition.overrideType ?? null;
    const length = definition.length ?? null;
    const minLength = definition.minLength ?? null;
    const maxLength = definition.maxLength ?? null;
    const options = definition.options ?? null;
    const transform = definition.transform ?? null;
    const regex = definition.regex ?? null;
    const isCellEmpty = cellValue === "";

    // Check if required
    if (isRequired) {
      if (isCellEmpty) {
        if (!transform.default) {
          const optionsLabel = options
            ? `. I.e. "${options.join('", "')}"`
            : "";

          return {
            passed: false,
            error: {
              title: "Value Required",
              message: `A value for "${colName}" is required`,
              solutions: [`Provide a value for "${colName}"${optionsLabel}`],
              row
            }
          };
        }
      }
    }

    // Check if default value is needed
    let newCellValue = cellValue;
    if (transform) newCellValue = transformValue(cellValue, transform);

    // Check for type
    switch (type) {
      case "string":
        if (!_.isString(newCellValue)) {
          if (overrideType) {
            if (typeof newCellValue !== overrideType) {
              return {
                passed: false,
                error: {
                  title: "Invalid Type",
                  message: `The value for "${colName}" is not a string`,
                  solutions: [
                    `Provide a valid string as the value for "${colName}"`
                  ],
                  row
                }
              };
            }
          } else {
            return {
              passed: false,
              error: {
                title: "Invalid Type",
                message: `The value for "${colName}" is not a string`,
                solutions: [
                  `Provide a valid string as the value for "${colName}"`
                ],
                row
              }
            };
          }
        }
        break;
      case "array":
        if (!_.isArray(newCellValue)) {
          return {
            passed: false,
            error: {
              title: "Invalid Type",
              message: `The value for "${colName}" is not an array`,
              solutions: [
                `Provide a valid array as the value for "${colName}"`
              ],
              row
            }
          };
        }
        break;
      case "boolean":
        if (!_.isBoolean(newCellValue)) {
          return {
            passed: false,
            error: {
              title: "Invalid Type",
              message: `The value for "${colName}" is not a boolean expression`,
              solutions: [
                `Provide a valid boolean expression as the value for "${colName}"`
              ],
              row
            }
          };
        }
        break;
      default:
      // Do nothing
    }

    // Check value options
    if (!isCellEmpty) {
      if (options) {
        if (!options.includes(newCellValue)) {
          return {
            passed: false,
            error: {
              title: "Value Is Not An Option",
              message: `A value for "${colName}" is not an option`,
              solutions: [
                `Provide a value for "${colName}" from the following list: "${options.join(
                  '", "'
                )}"`
              ],
              row
            }
          };
        }
      }
    }

    // Check lengths
    const isMeasurable = newCellValue?.toString() ? true : false;

    if (isMeasurable) {
      const newCellValueLength = newCellValue.toString().length;

      if (length) {
        if (newCellValueLength !== length) {
          return {
            passed: false,
            error: {
              title: "Invalid Length",
              message: `The value for "${colName}" should be exactly ${length} characters in length`,
              solutions: [
                `Correct the value for "${colName}" to be exactly ${length} characters in length`
              ],
              row
            }
          };
        }
      }

      if (minLength) {
        if (newCellValueLength < minLength) {
          return {
            passed: false,
            error: {
              title: "Invalid Length",
              message: `The value for "${colName}" should be at least ${minLength} characters in length`,
              solutions: [
                `Correct the value for "${colName}" to be at least ${minLength} characters in length`
              ],
              row
            }
          };
        }
      }

      if (maxLength) {
        if (newCellValueLength > maxLength) {
          return {
            passed: false,
            error: {
              title: "Invalid Length",
              message: `The value for "${colName}" should be at most ${maxLength} characters in length`,
              solutions: [
                `Correct the value for "${colName}" to be at most ${maxLength} characters in length`
              ],
              row
            }
          };
        }
      }
    }

    // Check patterns
    if (regex) {
      const re = new RegExp(regex, "i");

      if (!re.test(newCellValue)) {
        return {
          passed: false,
          error: {
            title: "Invalid Format",
            message: `The value for "${colName}" contains invalid characters`,
            solutions: [
              `Remove invalid characters from the "${colName}" expression and try again`
            ],
            row
          }
        };
      }
    }

    return { passed: true };
  };

  const validateData = (docs) => {
    const checks = {
      headers: {
        passed: null
      },
      format: {
        passed: null
      }
    };

    if (docs.length === 0) {
      checks.format.passed = false;
      checks.format.errors = [
        {
          title: "Missing Data in Template",
          message: "No crew member data were found in the template",
          solutions: [
            "Make sure to add rows of crew member data in the template",
            "Make sure the template is exported as or converted to a CSV file"
          ]
        }
      ];

      setErrors(checks);

      return false;
    }

    // Check headers
    const headers = getCrewMemberTemplateHeaders("delete");

    // Check first row headers
    const rowHeaders = Object.keys(docs[0]);

    // Check for missing columns in headers
    const missingCols = _.difference(headers, rowHeaders);

    // Set error if missing columns and exit
    if (missingCols.length > 0) {
      const plural = missingCols.length > 1;

      checks.headers.passed = false;
      checks.headers.errors = [
        {
          title: "Missing Columns in Template",
          message: `Missing column${plural ? "s" : ""}: "${missingCols.join(
            '", "'
          )}"`,
          solutions: [
            'Download the correct CSV template from the "Help & Resources" section above',
            `Add the missing column${plural ? "s" : ""} and ${
              plural ? "their" : "its"
            } values and re-upload the CSV file`
          ]
        }
      ];

      setErrors(checks);

      return false;
    } else {
      checks.headers.passed = true;
    }

    // Validate user type first
    for (let i = 0; i < docs.length; i++) {
      const doc = docs[i];
      const col = "type";
      const type = doc[col].trim();
      const definition = getCrewMemberFieldDefinition(col);

      const res = checkFormat(col, type, i + 1, definition);

      if (!res.passed) {
        if (checks.format.passed === null) checks.format.passed = false;
        if (!checks.format.errors) checks.format["errors"] = [];

        checks.format.errors.push(res.error);
      }
    }

    // Post format errors and exit
    if (checks.format.passed === false) {
      setErrors(checks);

      return false;
    }

    // Check data format
    for (let i = 0; i < docs.length; i++) {
      const doc = docs[i];
      const cols = Object.keys(doc);
      const type = doc["type"].trim();

      for (let j = 0; j < cols.length; j++) {
        const col = cols[j];
        const value = doc[col];
        const definition = getCrewMemberFieldDefinition(col);
        let res = null;

        // Handle exceptions to validation
        switch (col) {
          case "id":
            res = checkFormat(col, value, i + 1, definition.variations[type]);
            break;
          default:
            res = checkFormat(col, value, i + 1, definition);
        }

        if (!res.passed) {
          if (checks.format.passed === null) checks.format.passed = false;
          if (!checks.format.errors) checks.format["errors"] = [];

          checks.format.errors.push(res.error);
        }
      }
    }

    // Post format errors and exit
    if (checks.format.passed === false) {
      setErrors(checks);

      return false;
    } else {
      checks.format.passed = true;
    }

    // Passed all tests
    return true;
  };

  const ShowErrors = () => {
    const types = Object.keys(errors);

    const SubjectErrors = (props) => {
      const { type, subject } = props;

      return (
        <Card>
          <CardHeader tag="h6">{phraseToProperCase(type)} Errors</CardHeader>
          <CardBody>
            <ListGroup numbered="true">
              {subject.errors.map((error, i) => {
                const row = error.row ?? null;

                return (
                  <ListGroupItem key={i}>
                    <CardText>
                      <b>
                        {row ? `In Row ${row}: ` : ""}
                        {error.title}
                      </b>
                      <br />
                      {error.message}.
                    </CardText>
                    <CardText className="mt-1rem mb-025rem">
                      <b>Possible solutions:</b>
                    </CardText>
                    <ol>
                      {error.solutions.map((solution, i) => {
                        return <li key={i}>{solution}</li>;
                      })}
                    </ol>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </CardBody>
        </Card>
      );
    };

    return (
      <Card className="mb-1rem">
        <CardHeader tag="h6" className="bg-danger text-white">
          Validation Errors
        </CardHeader>
        <CardBody className="overflow-500">
          {types.map((type, i) => {
            if (errors[type].passed === false)
              return (
                <SubjectErrors key={i} type={type} subject={errors[type]} />
              );

            return null;
          })}
        </CardBody>
        <CardFooter>
          <Button
            outline
            color="danger"
            size="sm"
            onClick={() => setIsReporting(false)}
          >
            Done
          </Button>
        </CardFooter>
      </Card>
    );
  };

  const handleResultsDone = () => {
    setIsReporting(false);
    setLog([]);
  };

  return (
    <div className="DeleteCrewMemberDataComp">
      <Card>
        <CardHeader tag="h5" className="bg-danger text-white">
          Delete Crew Member Data
        </CardHeader>
        <CardBody>
          <ImportCrewMemberResourcesComp mode="delete" />
          {!isProcessing && !isReporting && (
            <Card className="mb-1rem">
              <CardHeader tag="h6">Upload CSV Data</CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit} id="upload-form">
                  <CardText>Please select a .csv file for validation</CardText>
                  <div className="form-group custom-file">
                    <input
                      id="data-file"
                      type="file"
                      className="custom-file-input"
                      disabled={isLoading}
                      onChange={handleCsvFileChange}
                    />
                    <label className="custom-file-label" htmlFor="data-file">
                      {filename ? filename : "Choose file"}
                    </label>
                  </div>
                  <Button
                    className="btn-lg"
                    disabled={!validateForm() || isLoading}
                    color="danger"
                    block
                  >
                    Upload {showSpinner && <Spinner color="light" />}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          )}
          {isProcessing && <ShowProcess />}
          {results && isReporting && (
            <Card className="mb-1rem">
              <CardHeader tag="h6">Data Update Results</CardHeader>
              <CardBody className="overflow-500">
                <ShowResults />
              </CardBody>
              <CardFooter>
                <Button
                  outline
                  color="danger"
                  size="sm"
                  onClick={handleResultsDone}
                >
                  Done
                </Button>
              </CardFooter>
            </Card>
          )}
          {errors && isReporting && <ShowErrors />}
          {DEBUG && (
            <Card>
              <CardHeader tag="h6">
                <b>DEBUG</b>
              </CardHeader>
              <CardBody className="overflow-500">
                <Json data={log} />
              </CardBody>
            </Card>
          )}
        </CardBody>
      </Card>
      <ErrorModal />
    </div>
  );
};

export default DeleteCrewMemberDataComp;
