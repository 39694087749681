import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner
} from "reactstrap";
import classnames from "classnames";
import {
  updateCrewMember,
  deleteCrewMember
} from "../services/crewMemberService";
import { phraseToProperCase } from "../libs/case-utils";
import Moment from "react-moment";
import "moment-timezone";
import "./CrewMember.css";

// Set the timezone for every instance.
Moment.globalTimezone = "America/Detroit";

// Set the output format for every react-moment instance.
Moment.globalFormat = "MM/DD/YYYY HH:mm";

const getTimestamp = () => new Date().getTime();

const CrewMember = (props) => {
  const { selectedUser, systemUserInfo, auth } = props;

  const [user, setUser] = useState(selectedUser);
  const [isDeleted, setIsDeleted] = useState(false);

  const cardHeaderClass = user.active
    ? "card-header-success"
    : "card-header-danger";

  // Control tabs
  const [activeTab, setActiveTab] = useState("1");
  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // Control modal
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const toggleModal = () => setOpenModal(!openModal);
  const closeBtn = (
    <button className="close" onClick={toggleModal}>
      &times;
    </button>
  );

  const ErrorModal = () => {
    return (
      <Modal
        className="msgModal"
        returnFocusAfterClose={true}
        isOpen={openModal}
      >
        <ModalHeader toggle={toggleModal} close={closeBtn}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalMsg}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal} block>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const showCustomModal = (params) => {
    const { title, message } = params;

    setModalTitle(title);
    setModalMsg(message);
    toggleModal();
  };

  const EditButton = (props) => {
    const { handleEdit } = props;

    return (
      <Button
        outline
        color="primary"
        size="sm"
        onClick={() => handleEdit(true)}
      >
        Edit
      </Button>
    );
  };

  const DeleteButton = (props) => {
    const { handleDelete } = props;

    return (
      <Button
        outline
        color="danger"
        size="sm"
        className="ml-05rem"
        onClick={() => handleDelete(true)}
      >
        Delete
      </Button>
    );
  };

  const SaveCancelButtons = (props) => {
    const { handleSave, handleCancel, disable, isLoading } = props;

    return (
      <>
        <Button
          color="primary"
          size="sm"
          disabled={disable}
          onClick={handleSave}
        >
          Save {isLoading && <Spinner size="sm" color="light" />}
        </Button>
        <Button
          className="ml-025rem"
          color="default"
          size="sm"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </>
    );
  };

  const DeleteCancelButtons = (props) => {
    const { handleDelete, handleCancelDelete, disable, isLoading } = props;

    return (
      <>
        <CardText>Are you sure you want to delete this crew member?</CardText>
        <Button
          color="success"
          size="sm"
          disabled={disable}
          onClick={handleCancelDelete}
        >
          Not sure
        </Button>
        <Button
          className="ml-05rem"
          color="danger"
          size="sm"
          disabled={disable}
          onClick={handleDelete}
        >
          Yes, I'm sure {isLoading && <Spinner size="sm" color="light" />}
        </Button>
      </>
    );
  };

  const CreatedByField = (props) => {
    const { by, at } = props;

    return (
      <>
        <b>Created by:</b>
        <br /> {by} {" @ "}
        <Moment>{at}</Moment>
      </>
    );
  };

  const UpdatedByField = (props) => {
    const { by, at } = props;

    return (
      <>
        <b>Updated by:</b>
        <br /> {by} {" @ "}
        <Moment>{at}</Moment>
      </>
    );
  };

  const GeneralTab = (props) => {
    const { userObj, handleUserUpdate } = props;

    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const [userName, setUserName] = useState(userObj.name);
    const [phoneNumber, setPhoneNumber] = useState(
      userObj.smsReceivers.phoneNumber ?? ""
    );
    const [org, setOrg] = useState(userObj.org);
    const [crew, setCrew] = useState(userObj.crew);
    const [serviceCenter, setServiceCenter] = useState(userObj.serviceCenter);
    const [active, setActive] = useState(userObj.active);

    const countryCode = userObj.smsReceivers.countryCode;

    const formFeedbackInit = {
      userName: {
        invalid: false,
        msg: ""
      },
      phoneNumber: {
        invalid: false,
        msg: ""
      },
      org: {
        invalid: false,
        msg: ""
      },
      crew: {
        invalid: false,
        msg: ""
      },
      serviceCenter: {
        invalid: false,
        msg: ""
      }
    };
    const [formFeedback, setFormFeedback] = useState(formFeedbackInit);

    const showError = (field, invalid, msg) => {
      const copy = { ...formFeedback };

      copy[field].invalid = invalid;
      copy[field].msg = msg;

      setFormFeedback(copy);
      setIsError(invalid);
    };

    const validateInput = () => {
      if (!validateUserName(userName)) return false;

      if (!validatePhoneNumber(phoneNumber)) return false;

      if (!validateOrg(org)) return false;

      if (!validateCrew(crew.join(","))) return false;

      if (!validateServiceCenter(serviceCenter.join(","))) return false;

      return true;
    };

    const handleSave = async () => {
      if (!validateInput()) return false;

      setIsLoading(true);

      const timestamp = getTimestamp();

      await updateCrewMember(
        userObj.id,
        {
          name: userName,
          org,
          crew: crew.filter((n) => n),
          serviceCenter: serviceCenter.filter((n) => n),
          smsReceivers: {
            countryCode: "1",
            phoneNumber: phoneNumber.length === 0 ? null : phoneNumber
          },
          active,
          updatedBy: systemUserInfo,
          updatedAt: timestamp
        },
        auth.idToken
      ).then(() => {
        setIsEditing(false);
        setIsLoading(false);

        handleUserUpdate({
          ...userObj,
          name: userName,
          org,
          crew: crew.filter((n) => n),
          serviceCenter: serviceCenter.filter((n) => n),
          smsReceivers: {
            countryCode: "1",
            phoneNumber: phoneNumber.length === 0 ? null : phoneNumber
          },
          active,
          updatedBy: systemUserInfo,
          updatedAt: timestamp
        });
      });
    };

    const handleCancel = () => {
      setIsEditing(false);

      setUserName(userObj.name);
      setPhoneNumber(userObj.smsReceivers.phoneNumber);
      setOrg(userObj.org);
      setCrew(userObj.crew);
      setServiceCenter(userObj.serviceCenter);
      setActive(userObj.active);
      setFormFeedback(formFeedbackInit);
    };

    const handleDelete = async () => {
      setIsLoading(true);

      await deleteCrewMember(userObj.id, auth.idToken).then((results) => {
        setIsDeleting(false);
        setIsLoading(false);

        if (results.deletedCount && results.deletedCount > 0) {
          setIsDeleted(true);
        } else {
          showCustomModal({
            title: "Error Deleting Crew Member",
            message:
              "Something wrong happened trying to delete crew member. Please contact the administrator."
          });
        }
      });
    };

    const handleCancelDelete = () => {
      setIsDeleting(false);
    };

    const validateUserName = (str) => {
      let status = true;

      if (str === "") {
        showError("userName", true, "Member name can't be empty");

        status = false;
      } else {
        showError("userName", false, "");

        if (str.length < 3) {
          showError(
            "userName",
            true,
            "Name must be at least 3 characters long"
          );

          status = false;
        } else {
          if (str.length > 64) {
            showError(
              "userName",
              true,
              "Name must be at most 64 characters long"
            );

            status = false;
          } else {
            if (!/^[A-Za-z \-']*$/.test(str)) {
              showError("userName", true, "Invalid character(s) in name");

              status = false;
            }
          }
        }
      }

      return status;
    };

    const handleUserName = (str) => {
      validateUserName(str);

      setUserName(str);
    };

    const validatePhoneNumber = (str) => {
      let status = true;

      if (str !== "") {
        showError("phoneNumber", false, "");

        if (str.length < 10) {
          showError(
            "phoneNumber",
            true,
            "Phone number must be 10 characters long"
          );

          status = false;
        } else {
          if (str.length > 10) {
            showError(
              "phoneNumber",
              true,
              "Phone number must be 10 characters long"
            );

            status = false;
          } else {
            if (!/^[0-9]{10}$/.test(str)) {
              showError(
                "phoneNumber",
                true,
                "Invalid character(s) in phone number"
              );

              status = false;
            }
          }
        }
      } else {
        showError("phoneNumber", false, "");
      }

      return status;
    };

    const handlePhoneNumber = (str) => {
      validatePhoneNumber(str);

      setPhoneNumber(str);
    };

    const validateOrg = (str) => {
      let status = true;

      if (str.length > 0) {
        showError("org", false, "");

        if (str.length < 3) {
          showError(
            "org",
            true,
            "If provided, organization must be at least 3 characters long"
          );

          status = false;
        } else {
          if (str.length > 64) {
            showError(
              "org",
              true,
              "If provided, organization must be at most 64 characters long"
            );

            status = false;
          } else {
            if (!/^[A-Za-z0-9 \-']*$/.test(str)) {
              showError("org", true, "Invalid character(s) in organization");

              status = false;
            }
          }
        }
      } else {
        showError("org", false, "");
      }

      return status;
    };

    const handleOrg = (str) => {
      // Optional field
      validateOrg(str);

      setOrg(str);
    };

    const validateCrew = (str) => {
      let status = true;

      if (str.length > 0) {
        showError("crew", false, "");

        if (str.length < 2) {
          showError(
            "crew",
            true,
            "Crew list must be at least 2 characters long"
          );

          status = false;
        } else {
          if (!/^[A-Z0-9,']*$/.test(str)) {
            showError(
              "crew",
              true,
              "Invalid character(s) in crew list. Uppercase letters or numbers only. Add comma for multiple crews."
            );

            status = false;
          }
        }
      } else {
        showError("crew", false, "");
      }

      return status;
    };

    const handleCrew = (str) => {
      const newStr = str.toUpperCase();

      validateCrew(newStr);

      const arr = newStr.split(",").map((o) => o.trim());
      setCrew(arr);
    };

    const validateServiceCenter = (str) => {
      let status = true;

      if (str.length > 0) {
        showError("serviceCenter", false, "");

        if (str.length < 2) {
          showError(
            "serviceCenter",
            true,
            "Service center list must be at least 2 characters long"
          );

          status = false;
        } else {
          if (!/^[A-Z0-9,']*$/.test(str)) {
            showError(
              "serviceCenter",
              true,
              "Invalid character(s) in service center list. Uppercase letters or numbers only. Add comma for multiple service centers."
            );

            status = false;
          }
        }
      } else {
        showError("serviceCenter", false, "");
      }

      return status;
    };

    const handleServiceCenter = (str) => {
      const newStr = str.toUpperCase();

      validateServiceCenter(newStr);

      const arr = newStr.split(",").map((o) => o.trim());
      setServiceCenter(arr);
    };

    const handleActive = (option) => {
      setActive(option);
    };

    const formatPhoneNumber = () => {
      let pnStr = "No set";

      if (phoneNumber) {
        const matches = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);

        pnStr = `+${countryCode} (${matches[1]}) ${matches[2]}-${matches[3]}`;
      }

      return pnStr;
    };

    const ActiveBadge = () => {
      const color = active ? "success" : "danger";

      return <Badge color={color}>{active ? "Yes" : "No"}</Badge>;
    };

    const TypeBadge = () => {
      const color = active ? "success" : "danger";

      return <Badge color={color}>{userObj.type.toUpperCase()}</Badge>;
    };

    return (
      <TabPane tabId="1">
        <CardBody>
          <FormGroup tag="fieldset">
            <CardText>
              <b>Id:</b> {userObj.id}
            </CardText>
            <CardText>
              <b>Type:</b> <TypeBadge />
            </CardText>
            {!isEditing && (
              <>
                <CardText>
                  <b>Name:</b> {userName === "" ? "Not set" : userName}
                </CardText>
                <CardText>
                  <b>Phone number:</b> {formatPhoneNumber()}
                </CardText>
                <CardText>
                  <b>Organization:</b> {org === "" ? "Not set" : org}
                </CardText>
                <CardText>
                  <b>Crew(s):</b> {crew.length ? crew.join(", ") : "Not set"}
                </CardText>
                <CardText>
                  <b>Service center:</b>{" "}
                  {serviceCenter.length ? serviceCenter.join(", ") : "Not set"}
                </CardText>
                <CardText>
                  <b>Active:</b> <ActiveBadge />
                </CardText>
              </>
            )}
            {isEditing && (
              <>
                <FormGroup>
                  <Label for={`name-${userObj.id}`}>
                    <b>
                      Name <sup>*</sup>:
                    </b>
                  </Label>
                  <Input
                    invalid={formFeedback.userName.invalid}
                    type="text"
                    name={`name-${userObj.id}`}
                    id={`name-${userObj.id}`}
                    disabled={isLoading}
                    placeholder={""}
                    value={userName}
                    onChange={(e) => handleUserName(e.target.value)}
                  />
                  <FormFeedback>{formFeedback.userName.msg}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="phoneNumber">
                    <b>Phone number:</b>
                  </Label>
                  <InputGroup>
                    <InputGroupText>+1</InputGroupText>
                    <Input
                      invalid={formFeedback.phoneNumber.invalid}
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      disabled={isLoading}
                      placeholder={"Enter 10 digits including area code"}
                      value={phoneNumber}
                      onChange={(e) => handlePhoneNumber(e.target.value)}
                    />
                    <FormFeedback>{formFeedback.phoneNumber.msg}</FormFeedback>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Label for={`org-${userObj.id}`}>
                    <b>Organization:</b>
                  </Label>
                  <Input
                    invalid={formFeedback.org.invalid}
                    type="text"
                    name={`org-${userObj.id}`}
                    id={`org-${userObj.id}`}
                    disabled={isLoading}
                    placeholder={""}
                    value={org}
                    onChange={(e) => handleOrg(e.target.value)}
                  />
                  <FormFeedback>{formFeedback.org.msg}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for={`crew-${userObj.id}`}>
                    <b>Crew(s):</b>
                  </Label>
                  <Input
                    invalid={formFeedback.crew.invalid}
                    type="text"
                    name={`crew-${userObj.id}`}
                    id={`crew-${userObj.id}`}
                    disabled={isLoading}
                    placeholder={"Comma-delimited string"}
                    value={crew.join(",")}
                    onChange={(e) => handleCrew(e.target.value)}
                  />
                  <FormFeedback>{formFeedback.crew.msg}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for={`serviceCenter-${userObj.id}`}>
                    <b>Service center:</b>
                  </Label>
                  <Input
                    invalid={formFeedback.serviceCenter.invalid}
                    type="text"
                    name={`serviceCenter-${userObj.id}`}
                    id={`serviceCenter-${userObj.id}`}
                    disabled={isLoading}
                    placeholder={"Comma-delimited string"}
                    value={serviceCenter.join(",")}
                    onChange={(e) => handleServiceCenter(e.target.value)}
                  />
                  <FormFeedback>{formFeedback.serviceCenter.msg}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for={`active-${userObj.id}`}>
                    <b>Active:</b>
                  </Label>
                  <CustomInput
                    type="switch"
                    name={`active-${userObj.id}`}
                    id={`active-${userObj.id}`}
                    label={active ? "Yes" : "No"}
                    disabled={isLoading}
                    checked={active}
                    onChange={(e) => handleActive(e.target.checked)}
                  />
                </FormGroup>
                <CardText>
                  <sup>*</sup> Required field
                </CardText>
              </>
            )}
          </FormGroup>
          {!isEditing && (
            <Card
              body
              inverse
              style={{ marginTop: "1.5rem", backgroundColor: "#f7f7f7" }}
            >
              <CardText style={{ color: "black" }}>
                <CreatedByField
                  by={userObj.createdBy.name}
                  at={userObj.createdAt}
                />
              </CardText>
              {userObj.updatedBy && (
                <CardText style={{ color: "black" }}>
                  <UpdatedByField
                    by={userObj.updatedBy.name}
                    at={userObj.updatedAt}
                  />
                </CardText>
              )}
            </Card>
          )}
        </CardBody>
        <CardFooter>
          {!isEditing && !isDeleting && (
            <EditButton handleEdit={setIsEditing} />
          )}
          {isEditing && (
            <SaveCancelButtons
              handleSave={handleSave}
              handleCancel={handleCancel}
              disable={isError || isLoading}
              isLoading={isLoading}
            />
          )}
          {!isDeleting && !isEditing && (
            <DeleteButton handleDelete={setIsDeleting} />
          )}
          {isDeleting && (
            <DeleteCancelButtons
              handleDelete={handleDelete}
              handleCancelDelete={handleCancelDelete}
              disable={isLoading}
              isLoading={isLoading}
            />
          )}
        </CardFooter>
      </TabPane>
    );
  };

  const OptionsTab = (props) => {
    const { userObj, handleUserUpdate } = props;

    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const options = userObj.options ?? {
      supervisor: false,
      tag: "",
      treeTrim: false,
      damageAssessment: false
    };

    const [supervisor, setSupervisor] = useState(options.supervisor);
    const [tag, setTag] = useState(options.tag);
    const [treeTrim, setTreeTrim] = useState(options.treeTrim);
    const [damageAssessment, setDamageAssessment] = useState(
      options.damageAssessment
    );

    const validateInput = () => {
      return true;
    };

    const handleSupervisor = (option) => {
      setSupervisor(option);
    };

    const handleTag = (option) => {
      setTag(option);
    };

    const handleTreeTrim = (option) => {
      setTreeTrim(option);
    };

    const handleDamageAssessment = (option) => {
      setDamageAssessment(option);
    };

    const handleSave = async () => {
      if (!validateInput()) return false;

      setIsLoading(true);

      const timestamp = getTimestamp();

      await updateCrewMember(
        userObj.id,
        {
          options: {
            supervisor,
            tag,
            treeTrim,
            damageAssessment
          },
          updatedBy: systemUserInfo,
          updatedAt: timestamp
        },
        auth.idToken
      ).then(() => {
        setIsEditing(false);
        setIsLoading(false);

        handleUserUpdate({
          ...userObj,
          options: {
            supervisor,
            tag,
            treeTrim,
            damageAssessment
          },
          updatedBy: systemUserInfo,
          updatedAt: timestamp
        });
      });
    };

    const handleCancel = () => {
      setIsEditing(false);

      setSupervisor(options.supervisor);
      setTag(options.tag);
      setTreeTrim(options.treeTrim);
      setDamageAssessment(options.damageAssessment);
    };

    return (
      <TabPane tabId="2">
        <CardBody>
          <FormGroup tag="fieldset">
            {!isEditing && (
              <>
                <CardText>
                  <b>Tag:</b>{" "}
                  {options.tag === ""
                    ? "Not set"
                    : phraseToProperCase(options.tag)}
                </CardText>
                <CardText>
                  <b>Supervisor:</b> {options.supervisor ? "Yes" : "No"}
                </CardText>
                <CardText>
                  <b>Tree trim:</b> {options.treeTrim ? "Yes" : "No"}
                </CardText>
                <CardText>
                  <b>Damage assessment:</b>{" "}
                  {options.damageAssessment ? "Yes" : "No"}
                </CardText>
              </>
            )}
            {isEditing && (
              <>
                <FormGroup>
                  <Label for={`tag-${userObj.id}`}>
                    <b>Tag</b>
                  </Label>
                  <CustomInput
                    type="select"
                    id={`tag-${userObj.id}`}
                    disabled={isLoading}
                    name={`tag-${userObj.id}`}
                    value={tag}
                    onChange={(e) => handleTag(e.target.value)}
                  >
                    <option value="">Select a tag</option>
                    <option value="employee">Employee</option>
                    <option value="contractor">Contractor</option>
                  </CustomInput>
                </FormGroup>
                <FormGroup>
                  <CustomInput
                    type="switch"
                    name={`supervisor-${userObj.id}`}
                    label="Supervisor"
                    id={`supervisor-${userObj.id}`}
                    disabled={isLoading}
                    checked={supervisor}
                    onChange={(e) => handleSupervisor(e.target.checked)}
                  />
                </FormGroup>
                <FormGroup>
                  <CustomInput
                    type="switch"
                    name={`treeTrim-${userObj.id}`}
                    label="Tree trim"
                    id={`treeTrim-${userObj.id}`}
                    disabled={isLoading}
                    checked={treeTrim}
                    onChange={(e) => handleTreeTrim(e.target.checked)}
                  />
                </FormGroup>
                <FormGroup>
                  <CustomInput
                    type="switch"
                    name={`damageAssessment-${userObj.id}`}
                    label="Damage assessment"
                    id={`damageAssessment-${userObj.id}`}
                    disabled={isLoading}
                    checked={damageAssessment}
                    onChange={(e) => handleDamageAssessment(e.target.checked)}
                  />
                </FormGroup>
              </>
            )}
          </FormGroup>
        </CardBody>
        <CardFooter>
          {!isEditing && <EditButton handleEdit={setIsEditing} />}
          {isEditing && (
            <SaveCancelButtons
              handleSave={handleSave}
              handleCancel={handleCancel}
              disable={isLoading}
              isLoading={isLoading}
            />
          )}
        </CardFooter>
      </TabPane>
    );
  };

  return (
    !isDeleted && (
      <div className="CrewMember">
        <Card className="UserCard">
          <CardHeader tag="h5" className={cardHeaderClass}>
            {user.name !== "" ? user.name : "Unknown Crew Member"}
          </CardHeader>
          <Nav tabs>
            <NavItem className="ml-025rem">
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTabs("1");
                }}
              >
                Info
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggleTabs("2");
                }}
              >
                Overrides
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <GeneralTab userObj={user} handleUserUpdate={setUser} />
            <OptionsTab userObj={user} handleUserUpdate={setUser} />
          </TabContent>
        </Card>
        <ErrorModal />
      </div>
    )
  );
};

export default CrewMember;
