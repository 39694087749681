import { useEffect, useState } from "react";
import { defer } from "rxjs";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { getUser } from "../services/userService";

export const useGetUser = (id) => {
  const [user, setUser] = useState(null);

  const { instance, accounts } = useMsal();

  useEffect(() => {
    if (!id) {
      return;
    }

    const fetchData = async (id) => {
      try {
        const token = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        });

        const user = await getUser(id, token.idToken);

        return { user, auth: token };
      } catch (e) {
        return { user: null, auth: null };
      }
    };

    const subscription = defer(() => fetchData(id)).subscribe(setUser);

    return () => {
      subscription.unsubscribe();
    };
  }, [id, instance, accounts]);

  return user;
};
