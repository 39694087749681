import React from "react";
import DeleteCrewMemberDataComp from "../components/DeleteCrewMemberDataComp";
import "./DeleteCrewMemberData.css";

const DeleteCrewMemberData = (props) => {
  return (
    <div className="DeleteCrewMemberData">
      <div className="container">
        <DeleteCrewMemberDataComp {...props} />
      </div>
    </div>
  );
};

export default DeleteCrewMemberData;
