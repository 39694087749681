import React from "react";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";

import "./Admin.css";

const Admin = (props) => {
  const { user } = props;

  // const Json = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>;

  return (
    <div className="Admin">
      <Card>
        <CardHeader tag="h2">Administrator</CardHeader>
        <CardBody>
          <CardTitle>
            {user.name}, you are logged on as administrator.
          </CardTitle>
          {/* <Json data={user} /> */}
        </CardBody>
      </Card>
    </div>
  );
};

export default Admin;
