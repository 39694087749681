import { bulkUpdate, bulkDelete } from "./bulkServices";

const upsertCrewMembers = async (filter, update, token) => {
  const query = {
    collection: "CrewMembers",
    filter,
    update,
    options: {
      upsert: true
    },
    confirm: true
  };

  const res = await bulkUpdate(query, token);

  // const res = {
  //   status: 200,
  //   response: {
  //     result: {
  //       query,
  //       upsertedCount: 1,
  //       modifiedCount: 0
  //     }
  //   }
  // };

  return res;
};

const deleteCrewMembers = async (filter, token) => {
  const query = {
    collection: "CrewMembers",
    filter,
    confirm: true
  };

  const res = await bulkDelete(query, token);

  // const res = {
  //   status: 200,
  //   response: {
  //     result: {
  //       query,
  //       upsertedCount: 1,
  //       modifiedCount: 0
  //     }
  //   }
  // };

  return res;
};

export { upsertCrewMembers, deleteCrewMembers };
